import { apiBase, apiEndpoints } from "@/shared/endpoints/api";
import { DocumentsGeneration } from "@/shared/interfaces/documentsGeneration.model";
import { Invitation } from "@/shared/interfaces/invitation.model";
import { Org } from "@/shared/interfaces/org.model";
import { Payment } from "@/shared/interfaces/payment.model";
import { Preferences } from "@/shared/interfaces/preferences.model";
import { Product } from "@/shared/interfaces/product.model";
import { Subscription } from "@/shared/interfaces/subscription.model";
import { Template } from "@/shared/interfaces/template.model";
import { User } from "@/shared/interfaces/user.model";
import hasAction from "@/shared/utils/hasAction";
import { getFile, getFilePath } from "@/shared/utils/requests";
import client, { AxiosError, AxiosResponse, Method as apiMethod } from "axios";
import { saveAs } from "file-saver";
import qs from "qs";
import { VueCookieNext } from "vue-cookie-next";
import { createStore } from "vuex";
import router from "../router";
import { Modal } from "@/shared/interfaces/modal.model";

client.defaults.baseURL = apiBase;
client.interceptors.request.use(
  function (config) {
    const token = VueCookieNext.getCookie(
      process.env.VUE_APP_TOKEN_COOKIE_NAME as string,
    );
    const is_microsoft = VueCookieNext.getCookie("is_microsoft");
    if (token && token.length > 0) {
      config.headers = {
        ...config.headers,
        Authorization: "Bearer " + token,
        is_microsoft,
      };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

const resolveGetGenerations = (
  context: any,
  response: AxiosResponse,
  resolve: any,
  isDesign = false,
) => {
  if (isDesign) context.commit("setDesigns", response.data.data);
  else context.commit("setGenerations", response.data.data);
  resolve(response);
};

const resolveGetPayments = (
  context: any,
  response: AxiosResponse,
  resolve: any,
) => {
  context.commit("setUserPayments", response.data.data);
  resolve(response);
};

const resolveGetInvitations = (
  context: any,
  response: AxiosResponse,
  resolve: any,
) => {
  context.commit("setInvitations", response.data.data);
  resolve(response);
};

const resolveGetOrgUsers = (
  context: any,
  response: AxiosResponse,
  resolve: any,
) => {
  context.commit("setOrgUsers", response.data.data);
  resolve(response);
};

const templatesHelpers = {
  getTemplatesPromise: (
    context: any,
    filters: any = { is_public: true },
  ): Promise<AxiosResponse> => {
    return new Promise((resolve, reject) => {
      client.get(apiEndpoints.getTemplates.url, { params: filters }).then(
        (response) => {
          context.commit("setUserTemplates", response.data.data);
          resolve(response);
        },
        (err) => {
          reject(err);
        },
      );
    });
  },
};

client.interceptors.response.use(
  function (res) {
    return res;
  },
  function (error) {
    if (
      error &&
      error.response &&
      error.response.status === 503 &&
      error.response.data &&
      error.response.data.errors
    ) {
      const maintenanceErr = error.response.data.errors.find(
        (err: any) => err.maintenance,
      );
      if (maintenanceErr) store.commit("setMaintenance", maintenanceErr);
    }
    return Promise.reject(error);
  },
);

const collectTags = (
  data: any,
  tags: Array<{ tag: string; description: string }>,
) => {
  Object.keys(data).forEach((key: any) => {
    if (typeof data[key] === "string")
      tags.push({ tag: key, description: data[key] });
    else collectTags(data[key], tags);
  });
  return tags;
};

const store = createStore({
  state: {
    lang: "" as string,
    loggedUser: {} as User,
    loggedUserOrg: {} as Org,
    loggedUserPreferences: {} as Preferences,
    availableCredits: 0 as number,
    products: [] as Product[],
    subscription: {} as Subscription,
    generations: [] as DocumentsGeneration[],
    designs: [] as DocumentsGeneration[],
    generationDetails: {} as DocumentsGeneration,
    userTemplates: [] as Template[],
    templatesTags: [] as { tag: string; description: string }[],
    userPayments: [] as Payment[],
    invitations: [] as Invitation[],
    orgUsers: [] as User[],
    maintenance: null as any,
    isAuthenticated: false as boolean,
    modal: {} as Modal,
  },
  mutations: {
    setLang: (state, newLang) => {
      state.lang = newLang;
    },
    setLoggedUser: (state, loggedUser: User) => {
      state.loggedUser = loggedUser;
    },
    setLoggedUserOrg: (state, loggedUserOrg: Org) => {
      state.loggedUserOrg = loggedUserOrg;
    },
    setLoggedUserPreferences: (state, loggedUserPreferences: Preferences) => {
      state.loggedUserPreferences = loggedUserPreferences;
    },
    setAvailableCredits: (state, availableCredits: number) => {
      state.availableCredits = availableCredits;
    },
    setProducts: (state, products: Array<Product>) => {
      state.products = products;
    },
    setSubscription: (state, subscription: Subscription) => {
      state.subscription = subscription;
    },
    setGenerations: (state, generations: Array<DocumentsGeneration>) => {
      state.generations = generations;
    },
    setDesigns: (state, designs: Array<DocumentsGeneration>) => {
      state.designs = designs;
    },
    setGenerationDetails: (state, details: DocumentsGeneration) => {
      state.generationDetails = details;
    },
    setUserTemplates: (state, userTemplates: Array<Template>) => {
      state.userTemplates = userTemplates;
    },
    setTemplatesTags: (
      state,
      templatesTags: Array<{ tag: string; description: string }>,
    ) => {
      state.templatesTags = templatesTags;
    },
    setUserPayments: (state, userPayments: Array<Payment>) => {
      state.userPayments = userPayments;
    },
    setInvitations: (state, invitations: Array<Invitation>) => {
      state.invitations = invitations;
    },
    setOrgUsers: (state, orgUsers: Array<User>) => {
      state.orgUsers = orgUsers;
    },
    setMaintenance: (state, maintenance: any) => {
      state.maintenance = maintenance;
      if (maintenance) router.push("/maintenance");
    },
    setIsAuthenticated: (state, isAuthenticated: boolean) => {
      state.isAuthenticated = isAuthenticated;
    },
    setModal: (state, modal: Modal) => {
      state.modal = modal;
    },
    setModalLoading(state, isLoading: boolean) {
      if (state.modal.props) {
        state.modal.props.isLoading = isLoading;
      }
    },
  },
  getters: {
    isAuthenticated: (state) => {
      return state.isAuthenticated;
    },
  },
  actions: {
    setToken: async (
      context,
      { token, isMicrosoft }: { token: string; isMicrosoft: boolean },
    ) => {
      VueCookieNext.setCookie(
        process.env.VUE_APP_TOKEN_COOKIE_NAME as string,
        token,
      );
      if (isMicrosoft) {
        VueCookieNext.setCookie("is_microsoft", "true");
      }
      context.commit("setIsAuthenticated", true);
    },
    removeGenerations: (context) => {
      context.commit("setGenerations", []);
      context.commit("setGenerationDetails", {});
    },
    removeToken: (context, logoutMessage: string | null) => {
      VueCookieNext.removeCookie(
        process.env.VUE_APP_TOKEN_COOKIE_NAME as string,
      );
      VueCookieNext.removeCookie("is_microsoft");
      context.commit("setLoggedUser", null);
      context.commit("setIsAuthenticated", false);
      context.dispatch("removeGenerations");
    },
    signUp: async (
      context,
      data: {
        firstname: string;
        lastname: string;
        email: string;
        password: string;
        password_confirm: string;
        org_name: string;
      },
    ) => {
      await client({
        method: apiEndpoints.signUp.method,
        url: apiEndpoints.signUp.url,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
      });
    },
    activate: async (context, { token, email }): Promise<boolean> => {
      return new Promise((resolve, reject) => {
        client
          .post(apiEndpoints.activate.url, { token, email })
          .then((response) => {
            if (response.data.success) resolve(true);
            else reject(new Error("activation failed"));
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    login: async (context, { email, password }): Promise<any> => {
      const data = {
        email,
        password,
      };
      await client({
        method: apiEndpoints.login.method,
        url: apiEndpoints.login.url,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: qs.stringify(data),
      })
        .then((response) => {
          const token = response.data.result.token;
          context.dispatch("setToken", { token });
        })
        .catch((error) => {
          throw error;
        });
    },
    loginDemo: async (context): Promise<any> => {
      return new Promise((resolve, reject) => {
        client({
          method: apiEndpoints.loginDemo.method,
          url: apiEndpoints.loginDemo.url,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    logout: async (context, logoutMessage: string | null) => {
      if (context.state.loggedUser) {
        await client
          .post(apiEndpoints.logout.url, { _id: context.state.loggedUser?._id })
          .then(() => {
            context.dispatch("removeToken", logoutMessage);
            context.commit("setIsAuthenticated", false);
            router.push({
              path: "/auth",
              query: { message: logoutMessage, type: "success" },
            });
          })
          .catch((err) => {
            context.dispatch("removeToken", logoutMessage);
            router.push({
              path: "/auth",
              query: { message: logoutMessage, type: "success" },
            });
            console.log(err);
          });
      } else {
        context.dispatch("removeToken", logoutMessage);
        router.push({
          path: "/auth",
          query: { message: logoutMessage, type: "success" },
        });
      }
    },
    getLoggedUser: async (context): Promise<User | undefined> => {
      try {
        const response = await client.get(apiEndpoints.me.url);
        const user: User = response.data.result;
        context.commit("setLoggedUser", user);
        return user;
      } catch (error) {
        context.commit("setLoggedUser", null);
        const axiosError = error as AxiosError;
        // if (axiosError.response && axiosError.response.status === 401) {
        //   context.dispatch("removeToken");
        // }

        throw error;
      }
    },
    updateLoggedUserPreferences: (context, data): Promise<Preferences> => {
      return new Promise((resolve, reject) => {
        client({
          method: apiEndpoints.updateUserPreferences.method,
          url: apiEndpoints.updateUserPreferences.url,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: qs.stringify(data),
        }).then(
          (response) => {
            context.commit("setLoggedUserPreferences", response.data);
            resolve(response.data);
          },
          (err) => {
            reject(err);
            console.log(err);
          },
        );
      });
    },
    getLoggedUserPreferences: (context): Promise<Preferences> => {
      return new Promise((resolve, reject) => {
        if (hasAction("can_get_current_user_preferences")) {
          client.get(apiEndpoints.userPreferences.url).then(
            (response) => {
              context.commit("setLoggedUserPreferences", response.data);
              resolve(response.data);
            },
            (err) => {
              context.commit("setLoggedUserPreferences", null);
              reject(err);
              console.log(err);
            },
          );
        } else
          reject(new Error("You don't have permoisions to do this action"));
      });
    },
    updateLoggedUser: (context, data): Promise<User> => {
      return new Promise((resolve, reject) => {
        client({
          method: apiEndpoints.update.method,
          url: apiEndpoints.update.url,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: qs.stringify(data),
        }).then(
          async (response) => {
            await context.dispatch("getLoggedUser");
            resolve(response.data.result);
          },
          (err) => {
            reject(err);
            console.log(err);
          },
        );
      });
    },
    getLoggedUserOrg: (context): Promise<Org> => {
      return new Promise((resolve, reject) => {
        client.get(apiEndpoints.myOrg.url).then(
          (response) => {
            context.commit("setLoggedUserOrg", response.data);
            resolve(response.data);
          },
          (err) => {
            context.commit("setLoggedUserOrg", null);
            reject(err);
            console.log(err);
          },
        );
      });
    },
    updateLoggedUserOrg: (
      context,
      {
        name,
        font_color,
        logo,
      }: { name: string; font_color: string; logo: File },
    ): Promise<Org> => {
      return new Promise((resolve, reject) => {
        const data = new FormData();
        if (name) data.append("name", name);
        if (font_color) data.append("font_color", font_color);
        if (logo) data.append("logo", logo);
        client({
          method: apiEndpoints.updateOrg.method,
          url: apiEndpoints.updateOrg.url,
          headers: { "Content-Type": "multipart/form-data" },
          data,
        }).then(
          (response) => {
            context.commit("setLoggedUserOrg", response.data);
            resolve(response.data);
          },
          (err) => {
            reject(err);
            console.log(err);
          },
        );
      });
    },
    getOrgUsers: async (
      context,
      filters = { sortBy: "_id", order: "DESC" },
    ): Promise<AxiosResponse> => {
      return new Promise((resolve, reject) => {
        client.get(apiEndpoints.getUsers.url, { params: filters }).then(
          (response) => {
            resolveGetOrgUsers(context, response, resolve);
          },
          (err) => {
            context.commit("setOrgUsers", null);
            reject(err);
          },
        );
      });
    },
    deleteOrgUser: async (context, user_id: number): Promise<boolean> => {
      return new Promise((resolve, reject) => {
        client
          .post(`${apiEndpoints.deleteUser.url}/${user_id}/delete`, {
            confirm_removal: true,
          })
          .then(
            () => {
              context.dispatch("getOrgUsers");
              resolve(true);
            },
            (err) => {
              reject(err);
            },
          );
      });
    },
    userDataRequest: async (context): Promise<AxiosResponse> => {
      return new Promise((resolve, reject) => {
        client.get(apiEndpoints.userDataRequest.url).then(
          (response) => {
            resolve(response.data);
          },
          (err) => {
            reject(err);
          },
        );
      });
    },
    upgradeDemoAccount: async (
      context,
      data: {
        firstname: string;
        lastname: string;
        email: string;
        password: string;
        password_confirm: string;
        org_name: string;
      },
    ): Promise<any> => {
      return new Promise((resolve, reject) => {
        client({
          method: apiEndpoints.upgradeDemoAccount.method,
          url: apiEndpoints.upgradeDemoAccount.url,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: qs.stringify(data),
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    forgotPassword: async (context, email): Promise<boolean> => {
      const data = {
        email,
      };
      return new Promise((resolve, reject) => {
        client({
          method: apiEndpoints.forgot.method,
          url: apiEndpoints.forgot.url,
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          data: qs.stringify(data),
        })
          .then(() => {
            resolve(true);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    resetPassword: (context, data): Promise<any> => {
      data.auth_by = "token";
      return new Promise((resolve, reject) => {
        client
          .post(apiEndpoints.reset.url, data)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    changePassword: (context, data): Promise<any> => {
      return new Promise((resolve, reject) => {
        if (hasAction("can_change_password")) {
          client
            .post(apiEndpoints.change.url, data)
            .then((response) => {
              resolve(response);
            })
            .catch((err) => {
              reject(err);
            });
        } else
          reject(new Error("You don't have permoisions to do this action"));
      });
    },
    upload: (
      context,
      data: {
        file?: File | string;
        design_correlation_id?: string;
        templates?: Template[];
        backendUrl: { method: apiMethod; url: string };
      },
    ): Promise<{ correlation_id: string; multiple_diagrams?: boolean }> => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("timestamp_millsec", new Date().getTime().toString());
        if (data.file) {
          formData.append("input_file", data.file);
        } else if (data.design_correlation_id) {
          formData.append("design_correlation_id", data.design_correlation_id);
        }
        if (data.templates && data.templates.length > 0)
          formData.append(
            "templates_ids",
            JSON.stringify(
              data.templates.map((template: Template) => template.id),
            ),
          );
        client({
          method: data.backendUrl.method,
          url: data.backendUrl.url,
          headers: { "Content-Type": "multipart/form-data" },
          data: formData,
        })
          .then((response: any) => {
            resolve({
              correlation_id: response.data.correlation_id,
              multiple_diagrams: response.data.multiple_diagrams,
            });
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },
    retryGeneration: (
      context: any,
      data: {
        correlationId: string;
        backendUrl: { method: apiMethod; url: string };
      },
    ): Promise<AxiosResponse> => {
      return new Promise((resolve, reject) => {
        client.post(`${data.backendUrl.url}${data.correlationId}/retry`).then(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          },
        );
      });
    },
    getGenerations: (
      context,
      filters = { sortBy: "date", order: "DESC" },
    ): Promise<AxiosResponse> => {
      return new Promise((resolve, reject) => {
        if (!filters.sortBy || filters.sortBy.length === 0)
          filters.sortBy = "date";
        if (!filters.order || filters.order.length === 0)
          filters.order = "DESC";
        client
          .get(apiEndpoints.getDocumentGenerations.url, { params: filters })
          .then(
            (response) => {
              resolveGetGenerations(context, response, resolve);
            },
            (err) => {
              context.commit("setGenerations", null);
              reject(err);
            },
          );
      });
    },
    getDesigns: (
      context,
      filters = { sortBy: "date", order: "DESC" },
    ): Promise<AxiosResponse> => {
      return new Promise((resolve, reject) => {
        if (!filters.sortBy || filters.sortBy.length === 0)
          filters.sortBy = "date";
        if (!filters.order || filters.order.length === 0)
          filters.order = "DESC";
        client
          .get(apiEndpoints.getDesignGenerations.url, { params: filters })
          .then(
            (response) => {
              resolveGetGenerations(context, response, resolve, true);
            },
            (err) => {
              context.commit("setDesigns", null);
              reject(err);
            },
          );
      });
    },
    getGenerationDetails: (
      context: any,
      data: {
        generation_id: string;
        backendUrl: { method: apiMethod; url: string };
      },
    ): Promise<AxiosResponse> => {
      return new Promise((resolve, reject) => {
        client.get(`${data.backendUrl.url}${data.generation_id}`).then(
          (response) => {
            context.commit("setGenerationDetails", response.data.result);
            resolve(response);
          },
          (err) => {
            context.commit("setGenerationDetails", null);
            reject(err);
          },
        );
      });
    },
    downloadFile: (
      context,
      { correlationId, filename, storage_type, generationType },
    ) => {
      return new Promise<void>((resolve, reject) => {
        if (storage_type === "S3") {
          getFile(correlationId, filename, generationType)
            .then((fileContent) => {
              saveAs(fileContent, filename);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        } else if (
          storage_type === "ONEDRIVE" ||
          storage_type === "CONFLUENCE"
        ) {
          getFilePath(correlationId, filename, generationType)
            .then((filePath) => {
              window.open(filePath, "_blank");
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        }
      });
    },
    previewPng: (
      context,
      data: {
        correlationId: string;
        filename: string;
        backendUrl: { method: apiMethod; url: string };
      },
    ) => {
      return new Promise<string>((resolve, reject) => {
        client({
          method: data.backendUrl.method,
          url: `${data.backendUrl.url}${data.correlationId}/download/${data.filename}`,
          headers: { "Content-Type": "application/json" },
          responseType: "blob",
        })
          .then(async (response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    feedback: async (context, data): Promise<string> => {
      return new Promise<string>((resolve, reject) => {
        client({
          method: apiEndpoints.feedback.method,
          url: apiEndpoints.feedback.url,
          headers: { "Content-Type": "application/json" },
          data,
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    contact: async (context, data): Promise<string> => {
      return new Promise<string>((resolve, reject) => {
        client({
          method: apiEndpoints.contact.method,
          url: apiEndpoints.contact.url,
          headers: { "Content-Type": "application/json" },
          data,
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getMicrosoftCode: (context, params): Promise<string> => {
      return new Promise<string>((resolve, reject) => {
        client({
          method: apiEndpoints.getMicrosoftCode.method,
          url: apiEndpoints.getMicrosoftCode.url,
          headers: { "Content-Type": "application/json" },
          params,
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getConfluenceAuthorizationLink: (context): Promise<string> => {
      return new Promise<string>((resolve, reject) => {
        if (hasAction("can_authorize_confluence")) {
          client({
            method: apiEndpoints.getConfluenceAuthorizationLink.method,
            url: apiEndpoints.getConfluenceAuthorizationLink.url,
            headers: { "Content-Type": "application/json" },
          })
            .then((response) => {
              resolve(response.data);
            })
            .catch((err) => {
              reject(err);
            });
        } else
          reject(new Error("You don't have permissions to do this action"));
      });
    },
    saveMicrosoftLogin: (context, data): Promise<string> => {
      return new Promise<string>((resolve, reject) => {
        client({
          method: apiEndpoints.saveMicrosoftLogin.method,
          url: apiEndpoints.saveMicrosoftLogin.url,
          headers: { "Content-Type": "application/json" },
          data,
        })
          .then(async (response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    acceptTc: (context, data): Promise<string> => {
      return new Promise<string>((resolve, reject) => {
        client({
          method: apiEndpoints.acceptTc.method,
          url: apiEndpoints.acceptTc.url,
          headers: { "Content-Type": "application/json" },
          data,
        })
          .then(async (response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getOnedriveCode: (context): Promise<string> => {
      return new Promise<string>((resolve, reject) => {
        if (hasAction("can_authorize_onedrive")) {
          client({
            method: apiEndpoints.getOnedriveCode.method,
            url: apiEndpoints.getOnedriveCode.url,
            headers: { "Content-Type": "application/json" },
          })
            .then((response) => {
              resolve(response.data);
            })
            .catch((err) => {
              reject(err);
            });
        } else
          reject(new Error("You don't have permissions to do this action"));
      });
    },
    saveOnedriveAuthorization: (context, data): Promise<string> => {
      return new Promise<string>((resolve, reject) => {
        client({
          method: apiEndpoints.saveOnedriveAuthorization.method,
          url: apiEndpoints.saveOnedriveAuthorization.url,
          headers: { "Content-Type": "application/json" },
          data,
        })
          .then(async (response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getGoogleCode: (context, params): Promise<string> => {
      return new Promise<string>((resolve, reject) => {
        client({
          method: apiEndpoints.getGoogleCode.method,
          url: apiEndpoints.getGoogleCode.url,
          headers: { "Content-Type": "application/json" },
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    saveGoogleLogin: (context, data): Promise<string> => {
      return new Promise<string>((resolve, reject) => {
        client({
          method: apiEndpoints.saveGoogleLogin.method,
          url: apiEndpoints.saveGoogleLogin.url,
          headers: { "Content-Type": "application/json" },
          data,
        })
          .then(async (response) => {
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getAvailableCredits: async (context): Promise<AxiosResponse> => {
      return new Promise((resolve, reject) => {
        client.get(apiEndpoints.getCredits.url).then(
          (response) => {
            context.commit(
              "setAvailableCredits",
              response.data.availableCredits,
            );
            resolve(response.data);
          },
          (err) => {
            context.commit("setAvailableCredits", 0);
            reject(err);
          },
        );
      });
    },
    getProducts: (context): Promise<any> => {
      return new Promise((resolve, reject) => {
        client
          .get(apiEndpoints.getProducts.url)
          .then((response) => {
            context.commit("setProducts", response.data);
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    buyProduct: (context, data): Promise<any> => {
      return new Promise((resolve, reject) => {
        client
          .post(apiEndpoints.buyProduct.url, {
            quantity: data.quantity,
            price_id: data.priceId,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getSubscription: (context): Promise<any> => {
      return new Promise((resolve, reject) => {
        client
          .get(apiEndpoints.getSubscription.url)
          .then((response) => {
            context.commit("setSubscription", response.data);
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cancelSubscription: (context, subscriptionId): Promise<any> => {
      return new Promise((resolve, reject) => {
        client
          .post(apiEndpoints.cancelSubscription(subscriptionId).url)
          .then((response) => {
            context.commit("setSubscription", {
              has_active_subscription: false,
            });
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    uploadTemplate: (context, data: { file: File }): Promise<any> => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("template_file", data.file);
        client({
          method: apiEndpoints.uploadTemplate.method,
          url: apiEndpoints.uploadTemplate.url,
          headers: { "Content-Type": "multipart/form-data" },
          data: formData,
        })
          .then(() => {
            resolve(true);
          })
          .catch((error: any) => {
            reject(error);
          });
      });
    },
    getTemplates: async (
      context,
      filters = { is_public: true },
    ): Promise<AxiosResponse> => {
      return templatesHelpers.getTemplatesPromise(context, filters);
    },
    getUserTemplates: (context, filters): Promise<AxiosResponse> => {
      const allFilters = { ...filters, is_public: false };
      return context.dispatch("getTemplates", allFilters);
    },
    deleteTemplate: async (context, id): Promise<AxiosResponse> => {
      return new Promise((resolve, reject) => {
        client.delete(`${apiEndpoints.deleteTemplate.url}/${id}`).then(
          (response) => {
            context.dispatch("getTemplates", { is_public: false });
            resolve(response.data);
          },
          (err) => {
            reject(err);
          },
        );
      });
    },
    getUserPayments: async (context, filters): Promise<AxiosResponse> => {
      return new Promise((resolve, reject) => {
        client.get(apiEndpoints.getPayments.url, { params: filters }).then(
          (response) => {
            resolveGetPayments(context, response, resolve);
          },
          (err) => {
            reject(err);
          },
        );
      });
    },
    getTemplatesTags: async (context): Promise<AxiosResponse> => {
      return new Promise((resolve, reject) => {
        client.get(apiEndpoints.getTemplatesTags.url).then(
          (response) => {
            const tags = collectTags(response.data.tags, []);
            context.commit("setTemplatesTags", tags);
            response.data.count = tags.length;
            resolve(response);
          },
          (err) => {
            reject(err);
          },
        );
      });
    },
    sendInvitation: async (context, data): Promise<string> => {
      return new Promise<string>((resolve, reject) => {
        client({
          method: apiEndpoints.sendInvitation.method,
          url: apiEndpoints.sendInvitation.url,
          headers: { "Content-Type": "application/json" },
          data,
        })
          .then((response) => {
            context.dispatch("getInvitations");
            resolve(response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getInvitations: async (context, filters = {}): Promise<AxiosResponse> => {
      filters.used = filters.used || false;
      filters.expired = filters.expired || false;
      filters.deleted = filters.deleted || false;
      return new Promise((resolve, reject) => {
        client.get(apiEndpoints.getInvitations.url, { params: filters }).then(
          (response) => {
            resolveGetInvitations(context, response, resolve);
          },
          (err) => {
            reject(err);
          },
        );
      });
    },
    deleteInvitation: async (context, id): Promise<AxiosResponse> => {
      return new Promise((resolve, reject) => {
        client.delete(`${apiEndpoints.deleteInvitation.url}/${id}`).then(
          (response) => {
            context.dispatch("getInvitations");
            resolve(response.data);
          },
          (err) => {
            reject(err);
          },
        );
      });
    },
    setModal: (context, modal: Modal) => {
      context.commit("setModal", modal);
    },
    updateModalLoading(context, isLoading: boolean) {
      context.commit("setModalLoading", isLoading);
    },
  },
  modules: {},
});

export default store;

export { resolveGetGenerations, templatesHelpers };
