
import BaseIcon from "@/shared/components/base-icon/BaseIcon.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { BaseIcon },
  props: {
    title: {
      type: String,
      required: false,
    },
    closeButton: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  mounted() {
    document.body.classList.add("no-scroll");
  },
  beforeUnmount() {
    document.body.classList.remove("no-scroll");
  },
  emits: ["close"],
  setup(props, { emit }) {
    const closeModal = () => {
      emit("close");
    };

    const handleBackdropClick = () => {
      if (props.closeButton) {
        closeModal();
      }
    };

    return {
      closeModal,
      handleBackdropClick,
    };
  },
});
