
import client from "axios";
import { defineComponent } from "vue";
import { changeLocale } from "@/i18n";
import { VueCookieNext } from "vue-cookie-next";
import { getErrorTranslationCode } from "./shared/utils/getErrorTranslationCode";
import { useToast } from "vue-toastification";
import Modal from "@/shared/components/modals/Modal.vue";
import DemoExpiredModalContent from "@/shared/components/modals/DemoExpiredModalContent.vue";

export default defineComponent({
  name: "App",
  setup: () => ({ toast: useToast() }),
  components: {
    Modal,
  },
  data: function () {
    return {
      isMenuOpened: false,
      isRegisterForm: false,
      showModal: false,
    };
  },
  methods: {
    openMenu($event: any) {
      $event.clicked = true;
      this.isMenuOpened = !this.isMenuOpened;
    },
    close($event: any) {
      if (!$event.clicked) this.isMenuOpened = false;
    },
    logout() {
      this.$store.dispatch("logout").catch((error: any) => {
        this.toast.error(this.$t(getErrorTranslationCode(error)));
      });
    },
  },
  beforeMount() {
    const locale = String(localStorage.getItem("language"));
    if (
      VueCookieNext.getCookie(process.env.VUE_APP_TOKEN_COOKIE_NAME as string)
    ) {
      this.$store.commit("setIsAuthenticated", true);
    }
    if (localStorage.getItem("language")) changeLocale(locale);
    client.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // Check that the user is on the document generation page
        const isDocumentGeneration = this.$route.matched.some(
          (x) => x.name === "Account",
        );

        if (error && error.response) {
          const status = error.response.status;
          const errorCode =
            error.response.data.errors &&
            error.response.data.errors[0] &&
            error.response.data.errors[0].errorCode;

          if (status === 401 && errorCode === "demo_token_expired") {
            if (isDocumentGeneration) {
              this.$store.dispatch("setModal", {
                title: "modals.demoExpired.title",
                component: DemoExpiredModalContent,
                closeButton: false,
              });
            }
            return Promise.resolve(error.response);
          } else if (status === 401 || status === 403) {
            this.$store.dispatch("removeToken");
            this.$store.dispatch("logout").catch((error: Error) => {
              this.toast.error(this.$t(getErrorTranslationCode(error)));
            });
          }
        }
        return Promise.reject(error);
      },
    );
  },
  computed: {
    isLoggedUser: function () {
      return this.$store.state.loggedUser && this.$store.state.loggedUser.email;
    },
    docsUrl() {
      return process.env.VUE_APP_DOCS_URL;
    },
    logoSrc() {
      return require("@/assets/logo.svg");
    },
  },
});
