import { createApp } from "vue";
import { VueCookieNext } from "vue-cookie-next";
import App from "./App.vue";
import i18n from "./i18n";
import router from "./router";
import store from "./store";
import Toast, { PluginOptions, POSITION, useToast } from "vue-toastification";
import ToastPlugin from "./toastPlugin";
import VueLazyload from "vue-lazyload";
import { createHead } from "@vueuse/head";

import "vue-multiselect/dist/vue-multiselect.css";
import "vue-toastification/dist/index.css";
import "../node_modules/nprogress/nprogress.css";
import "./styles/toast.scss";

const options: PluginOptions = {
  position: POSITION.TOP_CENTER,
  shareAppContext: true,
  toastClassName: "custom",
  bodyClassName: ["custom"],
  hideProgressBar: true,
  timeout: 5000,
  maxToasts: 1,
  filterBeforeCreate: (toast: any, toasts: any) => {
    if (toasts[0] && toasts[0].content === toast.content) return false;
    if (store.state.maintenance) return false;
    if (toast.type === "error") toast.timeout = false;

    const $toast = useToast();
    $toast.clear();

    return toast;
  },
};

createApp(App)
  .use(store)
  .use(router)
  .use(VueCookieNext)
  .use(i18n)
  .use(Toast, options)
  .use(ToastPlugin)
  .use(VueLazyload)
  .use(createHead())
  .mount("#app");
