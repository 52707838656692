<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99984 19.1571C14.6022 19.1571 18.3332 15.4261 18.3332 10.8237C18.3332 6.22136 14.6022 2.4904 9.99984 2.4904C5.39746 2.4904 1.6665 6.22136 1.6665 10.8237C1.6665 15.4261 5.39746 19.1571 9.99984 19.1571Z"
      stroke-width="1.68"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.6665 10.8237H18.3332"
      stroke-width="1.68"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.99984 2.4904C12.0842 4.77236 13.2688 7.73376 13.3332 10.8237C13.2688 13.9137 12.0842 16.8751 9.99984 19.1571C7.91544 16.8751 6.73088 13.9137 6.6665 10.8237C6.73088 7.73376 7.91544 4.77236 9.99984 2.4904V2.4904Z"
      stroke-width="1.68"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
