import store from "@/store/index";

function hasAction(requiredAction: string): boolean {
  const userActions: string[] =
    store.state.loggedUser && store.state.loggedUser.actions
      ? store.state.loggedUser.actions
      : [];
  return userActions.includes(requiredAction);
}

export default hasAction;
