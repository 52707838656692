import { Method as apiMethod } from "axios";

export const invitationsEndpoints = {
  sendInvitation: {
    method: "post" as apiMethod,
    url: "/orgs/invitations",
  },
  getInvitations: {
    method: "get" as apiMethod,
    url: "/orgs/invitations",
  },
  deleteInvitation: {
    method: "delete" as apiMethod,
    url: "/orgs/invitations",
  },
};
