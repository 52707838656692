import { apiEndpoints } from "@/shared/endpoints/api";
import client from "axios";

const downloadFileRequest = (
  correlationId: string,
  filename: string,
  responseType2: "blob" | "json",
  generationType: "generations" | "designs",
) => {
  const downloadUrl =
    generationType === "generations"
      ? apiEndpoints.downloadDocumentGenerations.url
      : apiEndpoints.downloadDesignGenerations.url;
  return client.get(`${downloadUrl}${correlationId}/download/${filename}`, {
    responseType: responseType2,
  });
};

export const getFile = (
  correlationId: string,
  filename: string,
  generationType: "generations" | "designs",
): Promise<Blob> => {
  return downloadFileRequest(correlationId, filename, "blob", generationType)
    .then((response) => response.data)
    .catch((error) => console.log(error));
};

export const getFilePath = (
  correlationId: string,
  filename: string,
  generationType: "generations" | "designs",
): Promise<string> => {
  return downloadFileRequest(correlationId, filename, "json", generationType)
    .then((response) => response.data.path)
    .catch((error) => console.log(error));
};
