<template>
  <p>{{ $t("modals.demoExpired.message") }}</p>
  <div class="flex flex-row gap-4 mt-10">
    <button class="button-primary" id="upgrade-account" @click="register">
      {{ $t("modals.demoExpired.actionButton") }}
    </button>
    <button class="bg-white text-black" id="go-to-homepage" @click="closeModal">
      {{ $t("modals.demoExpired.closeButton") }}
    </button>
  </div>
</template>

<script>
import UpgradeDemoAccount from "@/shared/components/modals/UpgradeDemoAccount.vue";

export default {
  methods: {
    closeModal() {
      this.$emit("close");
      this.$store.dispatch("setModal", {});
      this.$router.push("/");
    },
    register() {
      this.$store.dispatch("setModal", {
        title: "modals.demoExpired.title",
        component: UpgradeDemoAccount,
        closeButton: false,
      });
    },
  },
};
</script>
