<template>
  <svg
    id="Layer_1"
    version="1.1"
    height="20px"
    viewBox="0 0 50 50"
    width="20px"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <rect
      fill="none"
      height="10"
      stroke="#ffffff"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="2"
      width="16"
      x="17"
      y="1"
    />
    <rect
      fill="none"
      height="10"
      stroke="#ffffff"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="2"
      width="12"
      x="19"
      y="36"
    />
    <rect
      fill="none"
      height="10"
      stroke="#ffffff"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="2"
      width="12"
      x="1"
      y="36"
    />
    <rect
      fill="none"
      height="10"
      stroke="#ffffff"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="2"
      width="12"
      x="37"
      y="36"
    />
    <line
      fill="none"
      stroke="#ffffff"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="2"
      x1="25"
      x2="25"
      y1="36"
      y2="11"
    />
    <polyline
      fill="none"
      points="43,36 43,23 7,23   7,36 "
      stroke="#ffffff"
      stroke-linecap="round"
      stroke-miterlimit="10"
      stroke-width="2"
    />
  </svg>
</template>
