<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="20"
    width="22.5"
    viewBox="0 0 576 512"
    :class="colorClass"
  >
    <path
      :fill="color"
      d="M0 80C0 53.5 21.5 32 48 32h96c26.5 0 48 21.5 48 48V96H384V80c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H432c-26.5 0-48-21.5-48-48V160H192v16c0 1.7-.1 3.4-.3 5L272 288h96c26.5 0 48 21.5 48 48v96c0 26.5-21.5 48-48 48H272c-26.5 0-48-21.5-48-48V336c0-1.7 .1-3.4 .3-5L144 224H48c-26.5 0-48-21.5-48-48V80z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#EA3334",
    },
    colorClass: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
