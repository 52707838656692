<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1665 2.5C14.3854 2.28113 14.6452 2.10752 14.9312 1.98906C15.2171 1.87061 15.5236 1.80965 15.8332 1.80965C16.1427 1.80965 16.4492 1.87061 16.7352 1.98906C17.0211 2.10752 17.281 2.28113 17.4998 2.5C17.7187 2.71887 17.8923 2.97871 18.0108 3.26468C18.1292 3.55064 18.1902 3.85714 18.1902 4.16667C18.1902 4.4762 18.1292 4.7827 18.0108 5.06866C17.8923 5.35463 17.7187 5.61447 17.4998 5.83334L6.24984 17.0833L1.6665 18.3333L2.9165 13.75L14.1665 2.5Z"
      stroke-width="1.68"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
