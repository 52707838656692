import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["checked", "value", "id", "disabled"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-start", _ctx.disabled ? 'filter grayscale' : ''])
  }, [
    _createElementVNode("div", null, [
      _createElementVNode("input", {
        type: "checkbox",
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.check && _ctx.check(...args))),
        checked: _ctx.checked,
        value: _ctx.value,
        id: _ctx.fieldId,
        class: "inline-flex cursor-pointer align-middle me-5",
        disabled: _ctx.disabled
      }, null, 40, _hoisted_1),
      _createElementVNode("label", {
        for: _ctx.fieldId,
        class: "checkbox-label mb-0 inline-flex cursor-pointer select-none flex-wrap items-center align-middle me-5 font-bold text-white hover:text-secondary"
      }, [
        _createTextVNode(_toDisplayString(_ctx.$t(_ctx.labelText)) + " ", 1),
        _renderSlot(_ctx.$slots, "default")
      ], 8, _hoisted_2)
    ])
  ], 2))
}