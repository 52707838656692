import store from "@/store/index";
import dayjs from "dayjs";
import { computed, ref } from "vue";

const useLanguage = () => {
  const rtl = ref<boolean>(false);

  const setLanguage = (lang: string): void => {
    store.commit("setLang", lang);
    dayjs.locale(lang);
  };

  const setRTL = (isRTL: boolean): void => {
    rtl.value = isRTL;
  };

  const getLanguage = computed((): string => {
    return store.state.lang;
  });

  const isRTL = computed(() => {
    return rtl.value;
  });

  return {
    setLanguage,
    setRTL,
    getLanguage,
    isRTL,
  };
};

export default useLanguage;
