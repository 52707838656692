<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 1024 1024"
    class="icon"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    :class="colorClass"
  >
    <path
      d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "#FFFFFF",
    },
    colorClass: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
