import { Method as apiMethod } from "axios";

export const paymentEndpoints = {
  getProducts: {
    method: "get" as apiMethod,
    url: "/payments/products",
  },
  buyProduct: {
    method: "post" as apiMethod,
    url: "/payments",
  },
};
