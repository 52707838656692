import store from "@/store/index";
import routesMain from "@/views/account/router";
import routesAuth from "@/views/auth/router";
import routesStatic from "@/views/static/router";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useToast } from "vue-toastification";
import NProgress from "nprogress";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Static",
    component: () => import("../views/static/Static.vue"),
    children: routesStatic,
    beforeEnter: function (to, from, next) {
      const isMaintenance = process.env.VUE_APP_IS_MAINTENANCE === "true";
      if (
        store.getters.isAuthenticated &&
        !(isMaintenance || store.state.maintenance)
      ) {
        store.dispatch("getLoggedUser").catch(() => next());
        next();
      } else {
        next();
      }
    },
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import("../views/auth/Auth.vue"),
    children: routesAuth,
    beforeEnter: (to, from, next) => {
      if (!store.getters.isAuthenticated) {
        next();
      } else next({ path: from.path });
    },
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("../views/account/Account.vue"),
    children: routesMain,
    beforeEnter: async function (to, from, next) {
      const isMaintenance = process.env.VUE_APP_IS_MAINTENANCE === "true";
      if (isMaintenance || store.state.maintenance) {
        next({ path: "/maintenance" });
      } else {
        const isAuthenticated = store.getters.isAuthenticated;
        if (isAuthenticated) {
          await store.dispatch("getLoggedUser").catch((error) => {
            if (
              error.response.data.errors[0].errorCode === "demo_token_expired"
            ) {
              next();
            } else {
              next({ path: "/auth", query: { redirect: to.path } });
            }
          });
          next();
        } else {
          next({ path: "/auth", query: { redirect: to.path } });
        }
      }
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("../views/static/PageNotFound.vue"),
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.path !== from.path && !to.query.correlationId) {
        return { top: 0 };
      }
    }
  },
  history: createWebHistory(),
  routes,
});

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path.includes("/account")) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.beforeEach((to, from, next) => {
  const $toast = useToast();
  $toast.clear();

  to.meta.title
    ? (document.title = "NoLedge Tech - " + to.meta.title)
    : (document.title = "NoLedge Tech");

  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
