export const getErrorTranslationCode = (error: any) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.errors &&
    error.response.data.errors.length > 0 &&
    error.response.data.errors[0].errorCode
  ) {
    return `errors.${error.response.data.errors[0].errorCode}`;
  }
  return "errors.global";
};
