import hasAction from "@/shared/utils/hasAction";
import { RouteRecordRaw } from "vue-router";
import routesSettings from "./settings/router";

const routesAccount: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/account/documentation-generation",
  },
  {
    path: "documentation-generation",
    name: "DocumentationGeneration",
    component: () => import("./DocumentationGeneration.vue"),
  },
  {
    path: "design-generation",
    name: "DesignGeneration",
    component: () => import("./DocumentationGeneration.vue"),
  },
  {
    path: "settings",
    name: "Settings",
    component: () => import("./settings/Settings.vue"),
    children: routesSettings,
    meta: { title: "Settings" },
  },
  {
    path: "documentation-generation-details/:correlation_id",
    name: "DocumentationGenerationDetails",
    component: () => import("./GenerationDetails.vue"),
    meta: { title: "Generation Details" },
    beforeEnter: function (to, from, next) {
      if (hasAction("can_get_generation_details")) next();
      else next({ path: from.path });
    },
  },
  {
    path: "design-generation-details/:correlation_id",
    name: "DesignGenerationDetails",
    component: () => import("./GenerationDetails.vue"),
    meta: { title: "Generation Details" },
    beforeEnter: function (to, from, next) {
      if (hasAction("can_get_generation_details")) next();
      else next({ path: from.path });
    },
  },
  {
    path: "onedrive",
    name: "OnedriveAuthorization",
    component: () => import("./OnedriveAuthorization.vue"),
  },
  {
    path: "confluence-auth-success",
    name: "ConfluenceAuthorizationSuccess",
    component: () => import("./ConfluenceAuthSuccess.vue"),
  },
  {
    path: "payment-success",
    name: "PaymentSuccess",
    component: () => import("./PaymentSuccess.vue"),
  },
];

export default routesAccount;
