<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0001 18.3334C14.6025 18.3334 18.3334 14.6024 18.3334 10C18.3334 5.39765 14.6025 1.66669 10.0001 1.66669C5.39771 1.66669 1.66675 5.39765 1.66675 10C1.66675 14.6024 5.39771 18.3334 10.0001 18.3334Z"
      stroke-width="1.68"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66675 10H13.3334"
      stroke-width="1.68"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
