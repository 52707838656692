import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex min-h-screen items-start justify-center pt-24 text-center" }
const _hoisted_2 = { class: "modal-header pb-6" }
const _hoisted_3 = { class: "modal-title flex h-15 w-full items-center border-b border-borderColor text-2xl text-textColor" }
const _hoisted_4 = {
  key: 0,
  class: "font-bold ms-5"
}
const _hoisted_5 = {
  key: 1,
  class: "z-10 ms-auto me-5 text-textColor"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "modal-backdrop",
    class: "fixed inset-0 z-20 overflow-y-auto bg-black bg-opacity-70",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleBackdropClick && _ctx.handleBackdropClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "bg-backgroundColor text-textColor border-borderColor relative w-1/2 overflow-hidden rounded-lg shadow-xl text-start",
        id: "modal",
        role: "dialog",
        ref: "modal",
        "aria-modal": "true",
        "aria-labelledby": "modal-headline",
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.title)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.title), 1))
              : _createCommentVNode("", true),
            (_ctx.closeButton)
              ? (_openBlock(), _createElementBlock("button", _hoisted_5, [
                  _createVNode(_component_BaseIcon, {
                    name: "cross",
                    onClick: _ctx.closeModal
                  }, null, 8, ["onClick"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode("Modal content here")
        ])
      ], 512)
    ])
  ], 512))
}