<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99996 18.3334C14.6023 18.3334 18.3333 14.6024 18.3333 10C18.3333 5.39765 14.6023 1.66669 9.99996 1.66669C5.39759 1.66669 1.66663 5.39765 1.66663 10C1.66663 14.6024 5.39759 18.3334 9.99996 18.3334Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.57507 7.49999C7.77099 6.94304 8.1577 6.47341 8.6667 6.17426C9.1757 5.87512 9.77415 5.76577 10.3561 5.86558C10.938 5.96539 11.4658 6.26792 11.846 6.71959C12.2262 7.17126 12.4343 7.74292 12.4334 8.33332C12.4334 9.99999 9.93341 10.8333 9.93341 10.8333"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 14.1667H10.0083"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
