<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.85747 4.36819C7.68069 4.36819 7.52748 4.4389 7.41553 4.55086C7.30357 4.66282 7.22696 4.82191 7.23286 4.9928C7.23286 5.33457 7.5157 5.61741 7.85747 5.61741L13.5027 5.61741L4.55183 14.5683C4.31023 14.8099 4.31023 15.2105 4.55183 15.4521C4.79342 15.6937 5.19411 15.6937 5.43571 15.4521L14.3864 6.50143L14.3864 12.1464C14.3864 12.4881 14.6693 12.771 15.011 12.771C15.3528 12.771 15.6356 12.4881 15.6356 12.1464L15.6356 4.9928C15.6356 4.65103 15.3528 4.36819 15.011 4.36819L7.85747 4.36819Z"
      fill="#EA3334"
    />
  </svg>
</template>
