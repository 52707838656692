import { Method as apiMethod } from "axios";

export const preferencesEndpoints = {
  userPreferences: {
    method: "get" as apiMethod,
    url: "/users/preferences",
  },
  updateUserPreferences: {
    method: "patch" as apiMethod,
    url: "/users/preferences",
  },
};
