import { Method as apiMethod } from "axios";

export const userEndpoints = {
  me: {
    method: "get" as apiMethod,
    url: "/users/me",
  },
  update: {
    method: "patch" as apiMethod,
    url: "/users/update",
  },
  getOnedriveCode: {
    method: "get" as apiMethod,
    url: "/users/onedrive/authorize",
  },
  getConfluenceAuthorizationLink: {
    method: "get" as apiMethod,
    url: "/users/confluence/authorize",
  },
  getMicrosoftCode: {
    method: "get" as apiMethod,
    url: "/users/login/microsoft",
  },
  saveMicrosoftLogin: {
    method: "post" as apiMethod,
    url: "/users/login/microsoft/callback",
  },
  acceptTc: {
    method: "post" as apiMethod,
    url: "/users/acceptTc",
  },
  saveOnedriveAuthorization: {
    method: "post" as apiMethod,
    url: "/users/onedrive/authorize/callback",
  },
  getGoogleCode: {
    method: "get" as apiMethod,
    url: "/users/login/google",
  },
  saveGoogleLogin: {
    method: "post" as apiMethod,
    url: "/users/login/google/callback",
  },
  getCredits: {
    method: "get" as apiMethod,
    url: "/users/credits",
  },
  deleteUser: {
    method: "post" as apiMethod,
    url: "/users",
  },
  userDataRequest: {
    method: "get" as apiMethod,
    url: "/users/me/data",
  },
  upgradeDemoAccount: {
    method: "post" as apiMethod,
    url: "/users/upgradeDemo",
  },
};
