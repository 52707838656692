<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0001 10.8333C10.4603 10.8333 10.8334 10.4602 10.8334 10C10.8334 9.53977 10.4603 9.16667 10.0001 9.16667C9.53984 9.16667 9.16675 9.53977 9.16675 10C9.16675 10.4602 9.53984 10.8333 10.0001 10.8333Z"
      stroke-width="1.68"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.8333 10.8333C16.2936 10.8333 16.6667 10.4602 16.6667 10C16.6667 9.53977 16.2936 9.16667 15.8333 9.16667C15.3731 9.16667 15 9.53977 15 10C15 10.4602 15.3731 10.8333 15.8333 10.8333Z"
      stroke-width="1.68"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.16659 10.8333C4.62682 10.8333 4.99992 10.4602 4.99992 10C4.99992 9.53977 4.62682 9.16667 4.16659 9.16667C3.70635 9.16667 3.33325 9.53977 3.33325 10C3.33325 10.4602 3.70635 10.8333 4.16659 10.8333Z"
      stroke-width="1.68"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
