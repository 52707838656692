import { Method as apiMethod } from "axios";

export const orgEndpoints = {
  myOrg: {
    method: "get" as apiMethod,
    url: "/orgs",
  },
  updateOrg: {
    method: "patch" as apiMethod,
    url: "/orgs/details",
  },
  getUsers: {
    method: "get" as apiMethod,
    url: "/orgs/users",
  },
};
