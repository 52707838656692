import { Method as apiMethod } from "axios";

export const subscriptionEndpoints = {
  getSubscription: {
    method: "get" as apiMethod,
    url: "/subscriptions",
  },
  cancelSubscription: (subscriptionId: string) => ({
    method: "post" as apiMethod,
    url: `/subscriptions/${subscriptionId}/cancel`,
  }),
};
