<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1583 11.9987L11.1833 17.9737C11.0285 18.1287 10.8447 18.2516 10.6424 18.3355C10.44 18.4194 10.2232 18.4625 10.0041 18.4625C9.7851 18.4625 9.56822 18.4194 9.36589 18.3355C9.16356 18.2516 8.97975 18.1287 8.82496 17.9737L1.66663 10.8237V2.49039H9.99996L17.1583 9.64872C17.4687 9.96099 17.6429 10.3834 17.6429 10.8237C17.6429 11.264 17.4687 11.6864 17.1583 11.9987V11.9987Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.83337 6.65706H5.84171"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
