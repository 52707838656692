import hasAction from "@/shared/utils/hasAction";
import { RouteRecordRaw } from "vue-router";

const routesSettings: Array<RouteRecordRaw> = [
  {
    path: "person",
    name: "SettingsPerson",
    component: () => import("./Person.vue"),
  },
  {
    path: "preferences",
    name: "SettingsPreferences",
    component: () => import("./Preferences.vue"),
    beforeEnter: function (to, from, next) {
      if (hasAction("can_get_current_user_preferences")) next();
      else next({ path: from.path });
    },
  },
  {
    path: "password",
    name: "SettingsPassword",
    component: () => import("./Password.vue"),
    beforeEnter: function (to, from, next) {
      if (hasAction("can_change_password")) next();
      else next({ path: from.path });
    },
  },
  {
    path: "payments",
    name: "SettingsPayments",
    component: () => import("./Payments.vue"),
    beforeEnter: function (to, from, next) {
      if (hasAction("can_get_org_credits")) next();
      else next({ path: from.path });
    },
  },
  {
    path: "org",
    name: "SettingsOrg",
    component: () => import("./Org.vue"),
    beforeEnter: function (to, from, next) {
      if (hasAction("can_get_org_details")) next();
      else next({ path: from.path });
    },
  },
  {
    path: "templates",
    name: "SettingsTemplates",
    component: () => import("./Templates.vue"),
    beforeEnter: function (to, from, next) {
      if (hasAction("can_get_current_user_templates")) next();
      else next({ path: from.path });
    },
  },
  {
    path: "users",
    name: "SettingsUsers",
    component: () => import("./Users.vue"),
    beforeEnter: function (to, from, next) {
      if (
        hasAction("can_get_org_users") ||
        hasAction("can_create_invitations") ||
        hasAction("can_get_invitations")
      )
        next();
      else next({ path: from.path });
    },
  },
  {
    path: "privacy",
    name: "SettingsPrivacy",
    component: () => import("./Privacy.vue"),
    beforeEnter: function (to, from, next) {
      if (hasAction("can_request_user_data")) next();
      else next({ path: from.path });
    },
  },
];

export default routesSettings;
