<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1.66669V5.00002"
      stroke-width="1.68"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 10 10"
        to="360 10 10"
        dur="1.2s"
        repeatCount="indefinite"
      />
    </path>
    <path
      d="M10 15V18.3333"
      stroke-width="1.68"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 10 10"
        to="360 10 10"
        dur="1.2s"
        repeatCount="indefinite"
      />
    </path>
    <path
      d="M4.1084 4.10834L6.46673 6.46667"
      stroke-width="1.68"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 10 10"
        to="360 10 10"
        dur="1.2s"
        repeatCount="indefinite"
      />
    </path>
    <path
      d="M13.5332 13.5333L15.8915 15.8917"
      stroke-width="1.68"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 10 10"
        to="360 10 10"
        dur="1.2s"
        repeatCount="indefinite"
      />
    </path>
    <path
      d="M1.66699 10H5.00033"
      stroke-width="1.68"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 10 10"
        to="360 10 10"
        dur="1.2s"
        repeatCount="indefinite"
      />
    </path>
    <path
      d="M15 10H18.3333"
      stroke-width="1.68"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 10 10"
        to="360 10 10"
        dur="1.2s"
        repeatCount="indefinite"
      />
    </path>
    <path
      d="M4.1084 15.8917L6.46673 13.5333"
      stroke-width="1.68"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 10 10"
        to="360 10 10"
        dur="1.2s"
        repeatCount="indefinite"
      />
    </path>
    <path
      d="M13.5332 6.46667L15.8915 4.10834"
      stroke-width="1.68"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 10 10"
        to="360 10 10"
        dur="1.2s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
</template>
