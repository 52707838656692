import { Method as apiMethod } from "axios";

export const uploadEndpoints = {
  uploadDocuments: {
    method: "post" as apiMethod,
    url: "/documents/",
  },
  uploadDesigns: {
    method: "post" as apiMethod,
    url: "/designs/",
  },
  getDocumentGenerations: {
    method: "get" as apiMethod,
    url: "/documents/",
  },
  getDesignGenerations: {
    method: "get" as apiMethod,
    url: "/designs/",
  },
  downloadDocumentGenerations: {
    method: "get" as apiMethod,
    url: "/documents/",
  },
  downloadDesignGenerations: {
    method: "get" as apiMethod,
    url: "/designs/",
  },
  costs: {
    method: "get" as apiMethod,
    url: "/documents/costs",
  },
  uploadTemplate: {
    method: "post" as apiMethod,
    url: "/templates/",
  },
  getTemplates: {
    method: "get" as apiMethod,
    url: "/templates/metadata",
  },
  getTemplatesTags: {
    method: "get" as apiMethod,
    url: "/templates/tags",
  },
  deleteTemplate: {
    method: "delete" as apiMethod,
    url: "/templates",
  },
  getPayments: {
    method: "get" as apiMethod,
    url: "/payments",
  },
};
