<template>
  <div v-if="!registrationSucceed">
    <form v-if="!hasBeenSent" v-on:submit.prevent="send" class="mt-5">
      <FieldWrapper
        label="auth.signUp.firstname"
        name="firstname"
        :errors="v$.firstname ? v$.firstname.$errors : null"
      >
        <input
          name="firstname"
          type="text"
          id="firstname-input"
          v-bind:class="{ error: v$.firstname.$error }"
          v-model="firstname"
          class="focus:shadow"
          :placeholder="$t('auth.signUp.firstnamePlaceholder')"
          @blur="touch('firstname')"
        />
      </FieldWrapper>
      <FieldWrapper
        label="auth.signUp.lastname"
        name="lastname"
        :errors="v$.lastname ? v$.lastname.$errors : null"
      >
        <input
          name="lastname"
          type="text"
          id="lastname-input"
          v-bind:class="{ error: v$.lastname.$error }"
          v-model="lastname"
          class="focus:shadow"
          :placeholder="$t('auth.signUp.lastnamePlaceholder')"
          @blur="touch('lastname')"
        />
      </FieldWrapper>
      <FieldWrapper
        label="auth.email"
        name="email"
        :errors="v$.email ? v$.email.$errors : null"
      >
        <input
          name="email"
          type="email"
          id="email-input"
          v-bind:class="{ error: v$.email.$error }"
          v-model="email"
          class="focus:shadow"
          :placeholder="$t('auth.email')"
          @blur="touch('email')"
          :disabled="isEmailDisabled"
        />
      </FieldWrapper>
      <FieldWrapper
        label="auth.password"
        name="pass"
        :errors="v$.pass ? v$.pass.$errors : null"
      >
        <input
          name="pass"
          type="password"
          id="password-input"
          v-bind:class="{ error: v$.pass.$error }"
          v-model="pass"
          class="focus:shadow"
          :placeholder="$t('auth.password')"
          @blur="touch('pass')"
        />
      </FieldWrapper>
      <FieldWrapper
        label="auth.passwordConfirm"
        name="confirm_pass"
        :errors="v$.confirm_pass ? v$.confirm_pass.$errors : null"
      >
        <input
          name="confirm_pass"
          type="password"
          id="confirm-password-input"
          v-bind:class="{ error: v$.confirm_pass.$error }"
          v-model="confirm_pass"
          class="focus:shadow"
          :placeholder="$t('auth.password')"
          @blur="touch('confirm_pass')"
        />
      </FieldWrapper>
      <FieldWrapper
        label="auth.signUp.orgName"
        name="orgName"
        :errors="v$.orgName ? v$.orgName.$errors : null"
      >
        <input
          name="orgName"
          type="text"
          id="org-name-input"
          v-bind:class="{ error: v$.orgName.$error }"
          v-model="orgName"
          class="focus:shadow"
          :placeholder="$t('auth.signUp.orgNamePlaceholder')"
          @blur="touch('orgName')"
          :disabled="isOrgNameDisabled"
        />
      </FieldWrapper>
      <div v-if="showAgreement">
        <FieldWrapper
          name="agreement"
          :errors="[
            {
              $message:
                v$.agreement &&
                v$.agreement.$errors &&
                v$.agreement.$errors.length > 0
                  ? $t('auth.signUp.agreement.required')
                  : '',
            },
          ]"
        >
          <Checkbox
            class="text-white"
            v-model="agreement"
            value="true"
            labelText="auth.signUp.agreement.part1"
            fieldId="agreement"
          >
            <router-link
              to="/terms"
              target="_blank"
              id="register-terms-and-conditions"
              class="text-primary ms-2"
              >{{ $t("auth.signUp.agreement.part2") }}</router-link
            >
          </Checkbox>
        </FieldWrapper>
      </div>
      <div class="flex gap-4">
        <button
          type="submit"
          id="submit-button"
          class="button-primary mb-5 w-full"
        >
          <BaseIcon
            v-if="isLoading"
            name="indicator"
            classNames="h-5 w-5 animate-spin text-white me-3 -ms-1"
          />
          {{
            isInvitation
              ? $t("auth.signUp.join")
              : isUpgrade
              ? $t("modals.upgradeAccount.actionButton")
              : $t("buttons.createAccount")
          }}
        </button>
        <button
          v-if="isUpgrade && !$store.state.modal.closeButton"
          class="bg-white text-black mb-10 w-auto 2xl:mb-20"
          id="go-to-homepage"
          @click="closeModal"
        >
          {{ $t("modals.demoExpired.closeButton") }}
        </button>
      </div>
      <div v-if="!isUpgrade">
        <div>
          {{ $t("auth.signUp.haveAccount") }}
          <router-link
            to="/auth"
            id="login-button"
            class="mb-5 inline-block font-bold text-primary hover:underline"
            >{{ $t("auth.signUp.login") }}
          </router-link>
        </div>
      </div>
    </form>
  </div>
  <div
    v-if="registrationSucceed"
    id="success-message"
    class="text-2xl font-bold text-akcentGreen"
  >
    {{ $t("auth.signUp.succeed") }}
    <br /><br />
    {{ $t("auth.signUp.succeedSecond") }}
  </div>
</template>

<script>
import {
  alpha,
  complexityPassword,
  email,
  maxLength,
  requiredConfirmPassword,
  requiredValue,
  samePasswords,
} from "@/customValidators";
import i18n from "@/i18n";
import BaseIcon from "@/shared/components/base-icon/BaseIcon.vue";
import Checkbox from "@/shared/components/Checkbox.vue";
import FieldWrapper from "@/shared/components/FieldWrapper.vue";
import useVuelidate from "@vuelidate/core";
import { defineComponent } from "vue";
import { getErrorTranslationCode } from "@/shared/utils/getErrorTranslationCode";

export default defineComponent({
  name: "RegisterForm",
  props: {
    isUpgrade: {
      type: Boolean,
      default: false,
    },
    showAgreement: {
      type: Boolean,
      default: true,
    },
    isInvitation: {
      type: Boolean,
      default: false,
    },
    initialEmail: {
      type: String,
      default: "",
    },
    initialOrgName: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    return {
      v$: useVuelidate(),
      props,
    };
  },
  data: function () {
    return {
      firstname: "",
      lastname: "",
      email: this.initialEmail,
      pass: "",
      confirm_pass: "",
      orgName: this.initialOrgName,
      isLoading: false,
      registrationSucceed: false,
      agreement: [],
      isEmailDisabled: !!this.initialEmail,
      isOrgNameDisabled: !!this.initialOrgName,
    };
  },
  validations: function () {
    return {
      firstname: { requiredValue: requiredValue("first name"), alpha },
      lastname: { requiredValue: requiredValue("last name"), alpha },
      email: { requiredValue: requiredValue("email address"), email },
      pass: {
        requiredValue: requiredValue("password"),
        maxLength: maxLength(128),
        complexityPassword,
      },
      confirm_pass: {
        requiredConfirmPassword,
        samePasswords: samePasswords(this.pass, i18n.global.t("auth.password")),
      },
      orgName: { alpha },
      agreement: this.showAgreement ? { required: true } : {},
    };
  },
  components: {
    FieldWrapper,
    Checkbox,
    BaseIcon,
  },
  methods: {
    touch(fieldName) {
      if (this.v$[fieldName]) this.v$[fieldName].$touch();
    },
    send: async function () {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.isLoading = true;
      this.errorMessage = "";

      if (!this.orgName && !this.isInvitation) {
        this.orgName = `${this.firstname}_${this.lastname}`;
      }

      const payload = {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        password: this.pass,
        password_confirm: this.confirm_pass,
        org_name: this.orgName,
      };

      if (this.isInvitation) {
        payload.token = this.$route.query.token;
      }

      const action = this.isUpgrade ? "upgradeDemoAccount" : "signUp";

      await this.$store
        .dispatch(action, payload)
        .then(async () => {
          this.scrollToTop();
          this.isLoading = false;
          this.v$.$reset();
          this.firstname = "";
          this.lastname = "";
          this.email = "";
          this.pass = "";
          this.confirm_pass = "";
          if (this.isInvitation) {
            const successMsg = this.$t("auth.joiningSuccess");
            await this.$router.push({ path: "/auth" });
            this.$toast.success(successMsg);
          } else {
            this.registrationSucceed = true;
          }
          if (this.isUpgrade) {
            this.$store.dispatch("removeToken");
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.$toast.error(this.$t(getErrorTranslationCode(error)));
        });
    },
    closeModal() {
      this.$router.push("/");
      this.$emit("close");
      this.$store.dispatch("setModal", {});
    },
    scrollToTop: function () {
      window.scrollTo({ top: 0, left: 0 });
    },
  },
});
</script>
