
import { computed, defineComponent } from "vue";

export default defineComponent({
  emits: ["update:modelValue"],
  props: ["value", "labelText", "fieldId", "modelValue", "disabled"],
  setup(props, { emit }) {
    const checked = computed(() =>
      props.modelValue.find(
        (item: any) => JSON.stringify(item) === JSON.stringify(props.value),
      ),
    );

    const check = () => {
      if (!props.disabled) {
        if (!checked.value) {
          emit("update:modelValue", [...props.modelValue, props.value]);
        } else {
          emit(
            "update:modelValue",
            props.modelValue.filter(
              (item: any) =>
                JSON.stringify(item) !== JSON.stringify(props.value),
            ),
          );
        }
      }
    };

    return {
      checked,
      check,
    };
  },
});
