<template>
  <div class="p-10 m-auto w-600">
    <RegisterForm :isUpgrade="true" :showAgreement="false" />
  </div>
</template>

<script>
import RegisterForm from "@/shared/components/RegisterForm.vue";

export default {
  components: {
    RegisterForm,
  },
  methods: {
    closeModal() {
      this.$emit("close");
      this.$router.push("/");
    },
  },
};
</script>
