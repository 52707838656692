
import BaseModal from "@/shared/components/modals/BaseModal.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { BaseModal },
  computed: {
    modal() {
      return this.$store.state.modal;
    },
  },
  methods: {
    close() {
      this.$store.dispatch("setModal", {});
    },
  },
  emits: ["close"],
  setup(_, { emit }) {
    const onClose = (): void => {
      emit("close");
    };

    return {
      onClose,
    };
  },
});
