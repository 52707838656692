import store from "@/store/index";
import { RouteRecordRaw } from "vue-router";

const routesAuth: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () => import("./Home.vue"),
    meta: { title: "Do what matters! Leave the docs to us!" },
  },
  {
    path: "/cloud-migrations",
    name: "Migration",
    component: () => import("./CloudMigrations.vue"),
    meta: { title: "Migration" },
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: () => import("./AboutUs.vue"),
    meta: { title: "About Us" },
  },
  {
    path: "/customer-support",
    name: "Customer",
    component: () => import("./Customer.vue"),
    meta: { title: "Customer" },
  },
  {
    path: "/careers",
    name: "Careers",
    component: () => import("./Careers.vue"),
    meta: { title: "Careers" },
  },
  {
    path: "/talk",
    name: "Talk",
    component: () => import("./Talk.vue"),
    meta: { title: "Talk" },
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("./Terms.vue"),
    meta: { title: "Terms" },
  },
  {
    path: "/privacy-policy",
    name: "Policy",
    component: () => import("./Policy.vue"),
    meta: { title: "Policy" },
  },
  {
    path: "/coming-soon/:title",
    name: "ComingSoon",
    props: true,
    component: () => import("./ComingSoon.vue"),
    meta: { title: "Coming Soon" },
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    props: true,
    component: () => import("./Maintenance.vue"),
    meta: { title: "Maintenance" },
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: () => import("./Feedback.vue"),
    meta: { title: "Feedback" },
    beforeEnter: function (to, from, next) {
      if (
        process.env.VUE_APP_IS_MAINTENANCE === "true" ||
        store.state.maintenance
      ) {
        next({ path: "/maintenance" });
      } else next();
    },
  },
];

export default routesAuth;
