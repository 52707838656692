import { Method as apiMethod } from "axios";

export const authEndpoints = {
  me: {
    method: "get" as apiMethod,
    url: "/users/me",
  },
  signUp: {
    method: "post" as apiMethod,
    url: "/users/register",
  },
  activate: {
    method: "post" as apiMethod,
    url: "/users/activateAccount",
  },
  login: {
    method: "post" as apiMethod,
    url: "/users/login",
  },
  loginDemo: {
    method: "get" as apiMethod,
    url: "/users/login/demo",
  },
  logout: {
    method: "post" as apiMethod,
    url: "/users/logout",
  },
  forgot: {
    method: "post" as apiMethod,
    url: "/users/passwordForgotten",
  },
  reset: {
    method: "post" as apiMethod,
    url: "/users/changePassword",
  },
  change: {
    method: "post" as apiMethod,
    url: "/users/changePassword",
  },
};
