import { RouteRecordRaw } from "vue-router";

const routesAuth: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "Login",
    component: () => import("./Login.vue"),
    meta: { title: "Login" },
  },
  {
    path: "signup",
    name: "SignUp",
    component: () => import("./SignUp.vue"),
    meta: { title: "Sign Up" },
  },
  {
    path: "activate",
    name: "Activate",
    component: () => import("./ActivateAccount.vue"),
    meta: { title: "Activate" },
  },
  {
    path: "forgot",
    name: "Forgot Password",
    component: () => import("./ForgotPassword.vue"),
    meta: { title: "Forgot Password" },
  },
  {
    path: "reset",
    name: "Reset Password",
    component: () => import("./ResetPassword.vue"),
    meta: { title: "Reset Password" },
  },
  {
    path: "microsoft",
    name: "MicrosoftAuthorization",
    component: () => import("./MicrosoftAuthentication.vue"),
  },
  {
    path: "google",
    name: "GoogleAuthentication",
    component: () => import("./GoogleAuthentication.vue"),
  },
  {
    path: "terms-and-conditions",
    name: "AcceptTc",
    component: () => import("./AcceptTc.vue"),
  },
];

export default routesAuth;
