import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-5 pt-0 text-lg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (Object.keys(_ctx.modal).length > 0)
    ? (_openBlock(), _createBlock(_component_BaseModal, {
        key: 0,
        title: _ctx.$t(_ctx.modal.title),
        ref: "modal",
        onClose: _ctx.close,
        closeButton: _ctx.modal.closeButton
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modal.component), _mergeProps(_ctx.modal.props, { onClose: _ctx.close }), null, 16, ["onClose"]))
          ])
        ]),
        _: 1
      }, 8, ["title", "onClose", "closeButton"]))
    : _createCommentVNode("", true)
}