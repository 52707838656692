<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M91.6669 46.1668V50.0001C91.6617 58.9852 88.7522 67.7279 83.3724 74.9244C77.9925 82.1208 70.4305 87.3854 61.8142 89.933C53.1978 92.4806 43.9887 92.1747 35.5605 89.0609C27.1322 85.947 19.9362 80.1922 15.0458 72.6545C10.1554 65.1169 7.83263 56.2004 8.42382 47.2347C9.01501 38.2691 12.4885 29.7348 18.3263 22.9046C24.1641 16.0743 32.0534 11.3142 40.8176 9.33403C49.5818 7.35388 58.7513 8.25982 66.9585 11.9168"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M91.6667 16.6665L50 58.3748L37.5 45.8748"
      stroke-width="4"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
