<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" />
    <path
      d="M10 15C12.7614 15 15 12.7614 15 10C15 7.23858 12.7614 5 10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15Z"
      stroke-width="1.68"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 10H12"
      stroke-width="1.68"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
