import { authEndpoints } from "./auth";
import { feedbackEndpoints } from "./feedback";
import { invitationsEndpoints } from "./invitations";
import { orgEndpoints } from "./org";
import { paymentEndpoints } from "./payment";
import { preferencesEndpoints } from "./preferences";
import { subscriptionEndpoints } from "./subscription";
import { uploadEndpoints } from "./upload";
import { userEndpoints } from "./user";
import { contactEndpoints } from "./contact";

export const apiBase = process.env.VUE_APP_API_URL || "base";
export const apiMicrosoftBase = "https://login.microsoftonline.com";

export const apiEndpoints = {
  ...authEndpoints,
  ...userEndpoints,
  ...orgEndpoints,
  ...uploadEndpoints,
  ...feedbackEndpoints,
  ...paymentEndpoints,
  ...subscriptionEndpoints,
  ...preferencesEndpoints,
  ...invitationsEndpoints,
  ...contactEndpoints,
};
